import * as React from 'react';
import {StaticImage} from 'gatsby-plugin-image';
import Logo from '../../assets/svgs/logo-w.svg';
import Container from '../Container';
import {H1} from '../Typography';


const Hero = () => {
  return (
    <div className="grid h-screen-1/2">
      <StaticImage
        src="../../assets/images/mountains.jpg"
        alt="Rocky Mountains"
        layout="fullWidth"
        objectPosition="center"
        className="row-start-1 col-start-1"/>
      <div className="grid relative row-start-1 col-start-1 place-items-center text-white">
        <div className="w-full h-full flex flex-col justify-center items-center bg-black bg-opacity-60 ">
          <Container className="flex flex-col sm:flex-row justify-center items-center">
            <a href="/">
              <Logo className="w-72 md:w-[32rem] p-2 md:p-8 lg:p-16"/>
            </a>
            <H1 className="lg:ml-16">Portfolio</H1>
          </Container>
        </div>
      </div>
    </div>
  )
}

export default Hero;
