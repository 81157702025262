import * as React from 'react';
import Footer from '../comps/Footer';
import Hero from '../comps/portfolio/Hero';
import Projects from '../comps/portfolio/Projects';


const PortfolioPage = () => {

  return (
    <main className="font-sans">
      <Hero/>
      <Projects/>
      <Footer/>
    </main>
  )
}

export default PortfolioPage

export const Head = () => <title>Portfolio | Sarah Fotheringham Consulting</title>
