import {graphql, useStaticQuery} from 'gatsby';
import React from 'react';
import Container from '../Container';
import Project from '../Project';
import Section, {Title} from '../Section';

const Projects = () => {
  const {projects, clients} = useStaticQuery(graphql`
    query {
      projects: allProjectsJson {
        nodes {
          id
          title
          caption
          link
          pending
          category
          partners
          image {
            childImageSharp {
              gatsbyImageData(width: 512, quality: 100)
            }
          }
        }
      }
      clients: allClientsJson {
        nodes {
          id
          key
          name
          image {
            childImageSharp {
              gatsbyImageData(width: 128)
            }
          }
        }
      }
    }
  `)
  const projectsByCategory = projects.nodes.reduce((obj, n) => {
    (obj[n.category] = obj[n.category] || []).push(n);
    return obj;
  },{});
  const clientsByKey = clients.nodes.reduce((obj, n) => {
    obj[n.key] = n;
    return obj;
  },{});

  return (
    <div>
      <Container className="pt-8">
        <a href="/" className="text-primary font-thin uppercase">&laquo; Home</a>
      </Container>
      <Section name="evaluation">
        <Title title="Evaluation Reports" className="mb-8"/>
        <div className="flex flex-wrap w-full">
          {projectsByCategory['evaluation'].map((p,i) => <Project key={p.id} project={p} index={i} clients={clientsByKey}>
          </Project>)}
        </div>
      </Section>
      <Section name="program-design">
        <Title title="Program Designs" className="mb-8"/>
        <div className="flex flex-wrap w-full">
          {projectsByCategory['program-design'].map((p,i) => <Project key={p.id} project={p} index={i} clients={clientsByKey}>
          </Project>)}
        </div>
      </Section>
      <Section name="research">
        <Title title="Community-Based Research" className="mb-8"/>
        <div className="flex flex-wrap w-full">
          {projectsByCategory['research'].map((p,i) => <Project key={p.id} project={p} index={i} clients={clientsByKey}>
          </Project>)}
        </div>
      </Section>
      <Section name="publications">
        <Title title="Academic Publications" className="mb-8"/>
        <div className="flex flex-wrap w-full">
          {projectsByCategory['publications'].map((p,i) => <Project key={p.id} project={p} index={i} clients={clientsByKey}>
          </Project>)}
        </div>
      </Section>
    </div>
  )
}

export default Projects;
